<template>
  <div style="padding: 20px;height: auto;display: flex;flex-direction: column;align-items: center;justify-content: center;">
    <h1 style="margin-bottom: 50px">流量助推投后数据</h1>
    <el-form ref="form" :model="preFrom" label-width="80px">
      <el-form-item label="账户ID">
        <el-input v-model="preFrom.advertiser_id" />
        <span style="color: #a9a9a9">必填，聚星首页公司名称下方可见（快手ID）</span>
      </el-form-item>
      <el-form-item label="任务ID">
        <el-input v-model="preFrom.task_ids" type="textarea" />
        <p style="color: #a9a9a9;margin: 0">可选，最多20个，创建日期区间和任务id必须不同时为空</p>
      </el-form-item>
      <el-form-item label="订单类型">
        <el-radio-group v-model="preFrom.supplement_order_type">
          <el-radio-button v-for="type in orderType" :key="type.value" :label="type.value">{{ type.name }}</el-radio-button>
        </el-radio-group>
        <p style="color: #a9a9a9;margin: 0">可选，流量助推订单类型</p>
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker
          v-model="createTime"
          type="daterange"
          align="right"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00','23:59:59']"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
          @change="getDate"
        />
        <p style="color: #a9a9a9;margin: 0">可选，任务创建起始日期</p>
      </el-form-item>
      <el-form-item style="display: flex; align-items: center;justify-content: center">
        <el-button type="primary" :loading="loading" @click="onSubmit">立即获取</el-button>
        <el-button type="primary" @click="handlerExportRecord">导出记录</el-button>
      </el-form-item>
    </el-form>
    <el-dialog :title="exportRecord.title" :visible.sync="exportRecord.open" append-to-body>
      <el-table v-loading="loading" :data="exportRecord.dataList">
        <el-table-column label="导出账户" align="center" prop="exporterName" />
        <el-table-column label="导出时间" align="center" prop="exportTime" width="180">
          <template slot-scope="scope">
            <span>{{ parseTime(scope.row.exportTime, '{y}-{m}-{d} {h}:{i}:{s}') }}</span>
          </template>
        </el-table-column>
        <el-table-column label="文件名" align="center" prop="fileName" />
        <el-table-column label="导出状态" align="center" prop="exportState"/>
        <el-table-column label="错误原因" align="center" prop="failReason"/>
        <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-link :href="scope.row.exportPath">下载</el-link>
          </template>
        </el-table-column>
      </el-table>
      <pagination
          v-show="total>0"
          :total="total"
          :page.sync="queryParams.page"
          :limit.sync="queryParams.size"
          @pagination="getExportList"
      />
    </el-dialog>
  </div>
</template>

<script>
import { SupplementOrder } from '@/api/flowPush'
import { checkTokenBeforeRouteEnter } from '@/utils/authoVerify'
import {parseTime} from "@/utils/untilsMethod";
import Cookie from 'js-cookie'
import {exportList} from "@/api/exportFile";

const Type = [{ name: '星视频', value: 1 }, { name: '星任务-视频', value: 3 }]

export default {
  beforeRouteEnter(to, from, next) {
    checkTokenBeforeRouteEnter(to, from, next)
  },
  data() {
    return {
      loading: false,
      // 订单导出记录
      exportRecord: {
        open: false,
        title: '',
        dataList: []
      },
      orderType: Type,
      total:0,
      queryParams: {exportType:3,page:1,size:20},
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      createTime: undefined,
      preFrom: {
        advertiser_id: undefined,
        task_ids: undefined,
        create_start_time: undefined,
        create_end_time: undefined,
        supplement_order_type: 1,
        page_num: 1,
        page_size: 500
      },
      form: {}
    }
  },
  mounted() {
    this.preFrom.advertiser_id = Cookie.get('myID')
  },
  methods: {
    parseTime,
    handlerExportRecord() {
      return exportList({"exportType":3,"page":1,"size":20}).then(response =>{
        this.total = response.total
        this.exportRecord.dataList=response.rows
        this.exportRecord.open=true
        this.exportRecord.title="汇总流量助推数据导出记录"
      })
    },
    getExportList(){
      return exportList(this.queryParams).then(response =>{
        this.total = response.total
        this.exportRecord.dataList=response.rows
      })
    },
    onSubmit() {
      if (!this.preFrom.advertiser_id){
        this.$message.error("账户ID不能为空");
        return;
      }
      if (!this.preFrom.task_ids && !this.createTime){
        this.$message.error("任务ID和创建日期不能同时为空");
        return;
      }
      this.loading = true
      this.getForm()
      console.log(this.form)
      SupplementOrder(this.form).then(response => {
        this.$message.success(response.msg)
        this.reset()
        this.loading = false
      }).catch(() => {
        this.$message.error('请求失败')
        this.loading = false
      })
    },
    reset() {
      this.form = {}
    },
    getForm() {
      if (this.preFrom.advertiser_id) {
        this.form.advertiser_id = Number(this.preFrom.advertiser_id)
      }
      if (this.preFrom.task_ids) {
        this.form.task_ids = this.preFrom.task_ids.trim().split(/[,\s]+/)
      }
      if (this.preFrom.supplement_order_type) {
        this.form.supplement_order_type = Number(this.preFrom.supplement_order_type)
      }
      if (this.preFrom.page_size) {
        this.form.page_size = Number(this.preFrom.page_size)
      }
      if (this.preFrom.page_num) {
        this.form.page_num = Number(this.preFrom.page_num)
      }
      if (this.preFrom.create_start_time || this.preFrom.create_end_time) {
        this.form.create_start_time = this.preFrom.create_start_time
        this.form.create_end_time = this.preFrom.create_end_time
      }
    },
    getDate(value) {
      if (this.createTime) {
        this.preFrom.create_start_time = this.createTime[0]
        this.preFrom.create_end_time = this.createTime[1]
      }
      if (value == null) {
        this.createTime = undefined // 清空开始时间和结束时间
        this.preFrom.create_start_time = ''
        this.preFrom.create_end_time = ''
      }
    }
  }
}
</script>
